import $ from 'jquery'
import 'magnific-popup'
import './classes/polyfills.class.js'
import AnchorScroller from './classes/anchor-scroller'
import AOS from 'aos'
import CollapsibleItem from './classes/collapsible-item.class.js'
import FixedHeader from './classes/fixed-header.class'
import FixedIsi from './classes/fixed-isi.js'
import FormBehavior from './classes/form-behavior.class.js'
import MainMenu from './classes/main-menu.class.js'
import Carousel from './classes/carousel.class.js'
import Modal from './classes/modal.class.js'
import Violator from './classes/violator.class'

// Initialize Animate on Scroll
AOS.init({
  duration: 1200,
})

// Initialize AnchorScroller
const scroll = new AnchorScroller(
  $('header[role=banner]').height(),
  $('header[role=banner]').height(),
  768
)
scroll.init()

$(window).on('resize', function () {
  if ($('html').hasClass('enable_sticky_desktop')) {
    scroll.desktopOffset = $('header[role=banner]').height()
  }

  if ($('html').hasClass('enable_sticky_desktop')) {
    scroll.mobileOffset = $('header[role=banner]').height()
  }
}).trigger('resize')


// Initialize Modal Class
new Modal()

$(() => {
  // Prevent scroll to modal after click
  $('a').each(function () {
    const href = $(this).attr('href')
    if (!href || href === '#') return

    if (
      typeof href !== 'undefined'
      && href.startsWith('#')
      && $(href).hasClass('block--type--modal')
    ) {
      $(this).on('click', (e)=> e.stopImmediatePropagation())
    }
  })

  const isi = document.querySelector('.isi .fixed')
  if (isi) {
    new FixedIsi()
  }

  const violator = document.querySelector('.violator')
  if (violator) {
    new Violator(violator)
  }

  new FixedHeader()

  const mainMenu = document.querySelector('.layout-container > header')
  if (mainMenu) {
    new MainMenu(mainMenu)
  }

  // Don't active slideshows on the Layout Builder page
  if (
    $('.block--type--carousel-slide').length
    && !$('.node-page-layout-builder-form').length
  ) {
    $.uniqueSort(
      $('.block--type--carousel-slide')
        .parents('.layout__region')
    )
      .css({
        'min-width': 0
      })
      .each(function () {
        new Carousel(this)
      })
  }

  document.querySelectorAll('.webform-submission-form').forEach(f => {
    new FormBehavior(f)
  })

  document.querySelectorAll('.block--type--collapsible-item').forEach(item => {
    new CollapsibleItem(item)
  })

  if ($('.popup-youtube').length) {
    $('.popup-youtube').magnificPopup({
      type: 'iframe',
      iframe: {
        patterns: {
          youtube: {
            index: 'youtube.com/',
            id: 'v=',
            src: '//www.youtube.com/embed/%id%?autoplay=1&modestbranding=1'
          }
        }
      },
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    })
  }

  // HOMEPAGE
  const isHome = document.querySelector('.page-home')
  if (isHome) {
    const newsList = [
      {
        date: "MONTH XX, 20XX",
        title: "LOREM IPSUM DOLOR SIT AMET, CONSECTUR ELIT",
        preview: "Lorem ipsum dolor sit amet, consectetur adip iscing elit. Fusce quis laoreet lacus. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adip iscing elit. Fusce quis laoreet lacus…",
        link: "#"
      }, 
      {
        date: "MONTH XX, 20XX",
        title: "LOREM IPSUM DOLOR SIT AMET, CONSECTUR ELIT",
        preview: "Lorem ipsum dolor sit amet, consectetur adip iscing elit. Fusce quis laoreet lacus. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adip iscing elit. Fusce quis laoreet lacus…",
        link: "#"
      },
      {
        date: "MONTH XX, 20XX",
        title: "LOREM IPSUM DOLOR SIT AMET, CONSECTUR ELIT",
        preview: "Lorem ipsum dolor sit amet, consectetur adip iscing elit. Fusce quis laoreet lacus. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adip iscing elit. Fusce quis laoreet lacus…",
        link: "#"
      }
    ]
    const feedContainer = document.querySelector(".new_release__container")
    
    newsList.forEach(newsItem => {
      const template = `
        <div class="new_release__item shaped__container">
          <div class="new_release__shape shaped__wrapper">
            <span class="new_release__date">${newsItem.date}</span>
            <h3 class="new_release__title">${newsItem.title}</h3>
            <p class="new_release__preview">${newsItem.preview}</p>
            <a href="${newsItem.link}" class="new_release__link">
              <img src="/sites/default/files/styles/large/public/2024-08/circle-arrow.png?itok=8iPiZf9M" alt="Read More" width="43" height="43" loading="lazy">
            </a>
          </div>
        </div>
      `
      feedContainer.innerHTML += template
    })


    $(feedContainer).parent().addClass('carousel')
    const $slick_slider = $(feedContainer)
    const settings = {
      accessibility: true,
      arrows: false,
      dots: true,
      speed: 1000,
      mobileFirst: true,
      slidesToShow: 1.15,
      infinite: false,
      responsive: [
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1.5,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
          }
        }
      ]
    }
   
    if ($(window).width() < 1024) {
      $slick_slider.slick(settings);
    }

    $(window).on('resize', function() {
      if ($(window).width() > 1024) {
        if ($slick_slider.hasClass('slick-initialized')) {
          $slick_slider.slick('unslick');
        }
        return
      }
  
      if (!$slick_slider.hasClass('slick-initialized')) {
        return $slick_slider.slick(settings);
      }
    });

  }

  // LEADERSHIP PAGE
  const isLeardershipPage = window.location.pathname === '/about-us/our-leadership'

  if (isLeardershipPage) {
     // Board Selection
    const viewsElementContainer = document.querySelector('.views-element-container')
    const containerClass = window.location.search.replace('?board=', 'board-')
    const form = document.querySelector('.views-exposed-form')
    const teamViewWrapper = document.querySelector('.team-view-wrapper')
    const parent = teamViewWrapper.parentElement

    parent.insertBefore(form, teamViewWrapper)

    if (containerClass) viewsElementContainer.classList.add(containerClass)
    const container = document.querySelector('.fieldset-wrapper')

    const executiveTeamOption = document.querySelector('#edit-board div:nth-child(2)')
    const executiveTeamLabel = executiveTeamOption.querySelector('label')
    const isExecutiveActive = executiveTeamOption.querySelector('input').checked
    isExecutiveActive ? executiveTeamOption.classList.add('active')  : null

    const leadershipTeamOption = document.querySelector('#edit-board div:nth-child(3)')
    const leadershipTeamLabel = leadershipTeamOption.querySelector('label')
    const isLeadershipActive = leadershipTeamOption.querySelector('input').checked
    isLeadershipActive ? leadershipTeamOption.classList.add('active')  : null

    const boardOfDirectorsOption = document.querySelector('#edit-board div:nth-child(4)')
    const boardOfDirectorsLabel = boardOfDirectorsOption.querySelector('label')
    const isBoardOfDirectorsActive = boardOfDirectorsOption.querySelector('input').checked
    isBoardOfDirectorsActive ? boardOfDirectorsOption.classList.add('active') : null
    let isMobile = window.innerWidth < 1024
    let isUsingMobile = false

    const handleBoardOptionsMobile = () => {
      const bordOptionsTemplate = `
        <div class="board__options">
          <div class="option-1 ${isExecutiveActive ? 'active' : ''}">
            ${executiveTeamLabel.outerHTML}
          </div>

          <div class="option-2 ${isLeadershipActive ? 'active' : ''}">
            ${leadershipTeamLabel.outerHTML}
          </div>

          <div class="option-3 ${isBoardOfDirectorsActive ? 'active' : ''}">
            ${boardOfDirectorsLabel.outerHTML}
          </div>
        </div>
      `
      container.innerHTML += bordOptionsTemplate
      const options = document.querySelectorAll('.board__options div')
      options.forEach((option, index) => {
        option.addEventListener('click', ()=> {
          if (option.classList.contains('active')) return
          window.parent.location = `${window.location.origin}${window.location.pathname}?board=${index + 1}`
        })
      })

      const activeEl = document.querySelector('.form-item-board.active')
      activeEl.addEventListener('click', ()=> {
        const board = document.querySelector('.board__options')
        if (board) {
          board.classList.toggle('active')
        }
      })
      isUsingMobile = true
    }
    
    const handleBoardOptionsDesktop = () => {
      const options = document.querySelectorAll('#edit-board .form-item')
      options.forEach((option, index) => {
        option.addEventListener('click', ()=> {
          const option = event.currentTarget;
          if (option.classList.contains('active')) return;
          window.parent.location = `${window.location.origin}${window.location.pathname}?board=${index}`;
        });
      })
    }
    isMobile ? handleBoardOptionsMobile() : handleBoardOptionsDesktop()

    window.addEventListener('resize', ()=> {
      isMobile = window.innerWidth < 1024
      if (isMobile) {
        if (isUsingMobile) return
        handleBoardOptionsMobile()
        return
      }

      if (isUsingMobile) {
        const board = document.querySelector('.board__options')
        board.remove()
        isUsingMobile = false
        handleBoardOptionsDesktop()
        return
      }
      handleBoardOptionsDesktop()
    })

    // Employee Details
    const viewsRows = document.querySelectorAll('.views-row')
    
    viewsRows.forEach(row => {
      const button = document.createElement('button')
      button.classList.add('leadership__toggle')

      const name = row.querySelector('.views-field-info')
      const picture = row.querySelector('.views-field-field-picture')
      const role = row.querySelector('.views-field-field-role')
      const description = row.querySelector('.views-field-body')

      if (!containerClass || containerClass === 'board-1') {
        role.appendChild(button)
        button.addEventListener('click', ()=> openLeadershipModal(name, picture, role, description))
      }
      
    })

    const openLeadershipModal = (name, picture, role, description) => {
      const modal = document.createElement('div')
      modal.classList.add('leadership__modal')
      modal.style.top = `${window.scrollY}px`
  
      const modalContent = `
      <div class="leadership__modal__wrapper">
        <div class="leadership__modal__content-wrapper">
          <div class="leadership__modal__content">
              <div class="leadership__modal__close">
                <button class="leadership__modal__close__button">
                  <img src="/themes/custom/envivent/assets/images/global/close-btn.svg" alt="Close" width="24" height="24" loading="lazy">
                </button>
              </div>
              <div class="leadership-content__wrapper">
                <div class="leadership__modal__header">
                  <div class="leadership__modal__picture">
                    ${picture.innerHTML}
                  </div>
                  <div class="leadership__modal__info">
                    <h2 class="leadership__modal__name">${name.innerText}</h2>
      
                    <p class="leadership__modal__role">${role.innerText}</p>
                  </div>
                </div>
                <div class="leadership__modal__description">
                  ${description.innerHTML}
                </div>
              </div>
            </div>
          </div>
        </div>
      `
      modal.innerHTML = modalContent
      document.body.appendChild(modal)
      const page = document.querySelector('html')
      page.style.overflow = 'hidden'
      document.body.style.overflow = 'hidden'
  
      const closeButton = modal.querySelector('.leadership__modal__close__button')
      closeButton.addEventListener('click', ()=> {
        document.body.removeChild(modal)
        page.style.overflow = 'auto'
        document.body.style.overflow = 'auto'
      })
      
    }
  }

  
  // Diversity scripts

  const isDeiPage = window.location.pathname === '/about-us/diversity-equity-inclusion'
  if (isDeiPage) {
    const cards = document.querySelectorAll('.employee_resource__cards .card')

    cards.forEach(card => {
      card.addEventListener('click', ()=> {
        card.classList.toggle('active')
      })
    })
  }

  // Accordions

  const accordions = document.querySelectorAll('.accordion')
  if (accordions.length) {
    accordions.forEach(accordion => {
      const accordionButton = accordion.querySelector('button')
      accordionButton.addEventListener('click', ()=> {
        accordion.classList.toggle('active')
      })
    })
  }

  const gridAccordion = document.querySelectorAll('.accordion-item')

  if (gridAccordion) {
    gridAccordion.forEach(item => {
      const button = item.querySelector('.accordion-item__header')
      const content = item.querySelector('.accordion-item__body')
      button.addEventListener('click', ()=> {
        button.classList.toggle('active')
        content.classList.toggle('active')
      })
    })
  }


  // Footer 
  const footer = document.querySelector('footer > div')
  const backToTopLayout = `
    <div class="footer-back-to-top">
      <button class="back-to-top__link">
        Back to Top <img src="/themes/custom/envivent/assets/images/global/arrow-down.svg" alt="" width="16" height="23" loading="lazy">
      </button>
    </div>
  ` 

  footer.innerHTML += backToTopLayout
  const backToTop = document.querySelector('.footer-back-to-top')
  backToTop.addEventListener('click', ()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  })


  // Pipeline Page

  const isPipelinePage = window.location.pathname === '/science-and-innovation/pipeline' 

  if (isPipelinePage) {
    const searchFilter = document.querySelector('.search-filter')
    const searchToggle = document.querySelector('.search-filter__toggle')

    searchToggle.addEventListener('click', ()=> {
      searchFilter.classList.toggle('active')
    })

    const pipelineToggle = document.querySelectorAll('.pipeline-body__main')
    
    pipelineToggle.forEach(toggle => {
      toggle.addEventListener('click', ()=> {
        const parent = toggle.parentElement
        const father = parent.parentElement
        const button = parent.querySelector('button')
        parent.classList.toggle('active')
        const hasActive = document.querySelector('.pipeline-item__body.active')
        if (hasActive) {
          father.classList.add('is__expanded')
        } else {
          father.classList.remove('is__expanded')
        }

        button.addEventListener('click', ()=> {
          parent.classList.remove('active')
        })
      })
    })

    // Search Filter funcitonality
    const partnerInput = document.querySelector('.checkbox__option #partnered')
    const ionisOwnedInput = document.querySelector('.checkbox__option #ionis-owned')
    const neurologyInput = document.querySelector('.checkbox__option #neurology')
    const cardiologyInput = document.querySelector('.checkbox__option #cardiology')
    const rareInput = document.querySelector('.checkbox__option #rare')
    const otherMedicinesInput = document.querySelector('.checkbox__option #other-medicines')
    const phase1 = document.querySelector('.checkbox__option #phase-1')
    const phase2 = document.querySelector('.checkbox__option #phase-2')
    const phase3 = document.querySelector('.checkbox__option #phase-3')
    const searchTextInput = document.querySelector('.search-filter__filter input')

    // Elements
    const pipelineWrapper = document.querySelector('.pipeline__items')
    const pipelineItems = document.querySelectorAll('.pipeline-item__body')
    const searchButton = document.querySelector('.search-filter__options button')
    const neurologyWrapper = document.querySelector('.pipeline__item.neurology')
    const cardiologyWrapper = document.querySelector('.pipeline__item.cardiology')
    const rareWrapper = document.querySelector('.pipeline__item.rare')
    const otherMedicinesWrapper = document.querySelector('.pipeline__item.other-medicines')


    let isCheckedpartnerInput = partnerInput.checked
    let isCheckedionisOwnedInput = ionisOwnedInput.checked
    let isCheckedneurologyInput = neurologyInput.checked
    let isCheckedcardiologyInput = cardiologyInput.checked
    let isCheckedrareInput = rareInput.checked
    let isCheckedotherMedicinesInput = otherMedicinesInput.checked
    let isCheckedphase1 = phase1.checked
    let isCheckedphase2 = phase2.checked
    let isCheckedphase3 = phase3.checked
    let isCheckedsearchTextInput = searchTextInput.value
    const noResults = document.createElement('div')

    searchButton.addEventListener('click', ()=> {
      clearFilters()
      isCheckedpartnerInput = partnerInput.checked
      isCheckedionisOwnedInput = ionisOwnedInput.checked
      isCheckedneurologyInput = neurologyInput.checked
      isCheckedcardiologyInput = cardiologyInput.checked
      isCheckedrareInput = rareInput.checked
      isCheckedotherMedicinesInput = otherMedicinesInput.checked
      isCheckedphase1 = phase1.checked
      isCheckedphase2 = phase2.checked
      isCheckedphase3 = phase3.checked
      isCheckedsearchTextInput = searchTextInput.value
      pipelineWrapper.classList.add('has__search')
      pipelineItems.forEach(checkVisibility)

      const visibleItems = document.querySelectorAll('.pipeline-item__body.visible')
      if (visibleItems.length === 0) {
        
        noResults.classList.add('no__results__message')
        noResults.innerHTML = 'No results found for selected filters.'
        pipelineWrapper.appendChild(noResults)
      } else {
        pipelineWrapper.classList.remove('no__results')
      }

      const wrappersArray = [neurologyWrapper, cardiologyWrapper, rareWrapper, otherMedicinesWrapper]
      wrappersArray.forEach((wrapper) => {
        const visibleItems = wrapper.querySelectorAll('.pipeline-item__body.visible')
        if (visibleItems.length > 0) {
          wrapper.classList.remove('hidden')
          return
        } 
        wrapper.classList.add('hidden')
      })
      
    })

    const checkVisibility = (el) => {
      const partnerType = el.querySelector('.pipeline-body__partner').dataset.partner;
      const phase = el.querySelector('.pipeline-body__indicator').dataset.phase;
      const visiblePhases = [];
      const parent = el.parentElement.parentElement.parentElement
      if (isCheckedphase1) visiblePhases.push('1');
      if (isCheckedphase2) visiblePhases.push('2');
      if (isCheckedphase3) visiblePhases.push('3');
      
      const isPartnerVisible = (isCheckedpartnerInput && partnerType === 'partner') || (isCheckedionisOwnedInput && partnerType === 'ionis-owned') || (!isCheckedpartnerInput && !isCheckedionisOwnedInput);
      const isPhaseVisible = visiblePhases.includes(phase) || (!isCheckedphase1 && !isCheckedphase2 && !isCheckedphase3);
      const isSearched = el.innerText.toLowerCase().includes(isCheckedsearchTextInput.toLowerCase()) || !isCheckedsearchTextInput;
      const isAreaVisible = (isCheckedneurologyInput && parent.classList.contains('neurology')) || (isCheckedcardiologyInput && parent.classList.contains('cardiology')) || (isCheckedrareInput && parent.classList.contains('rare')) || (isCheckedotherMedicinesInput && parent.classList.contains('other-medicines')) || (!isCheckedneurologyInput && !isCheckedcardiologyInput && !isCheckedrareInput && !isCheckedotherMedicinesInput);
      
      if (isPartnerVisible && isPhaseVisible && isSearched && isAreaVisible) {
        el.classList.add('visible');
      } else {
        el.classList.remove('visible');
      }
    }


    const clearFilters = () => {
      pipelineWrapper.classList.remove('has__search')
      noResults.remove()
      pipelineItems.forEach(element => {
        element.classList.remove('visible')
      })
    }
  }

  // Patient Story Page

  const isPatientStoryPage = window.location.pathname === '/patients-and-advocacy/patient-stories'
  if (isPatientStoryPage) {
    const stories = document.querySelectorAll('.patient-stories__section .card')

    stories.forEach(story => {
      const button = story.querySelector('button')
      const fullStory = story.querySelector('.full-story__wrapper')
      const modalContent = `
      <div class="patient-stories__modal__wrapper">
        <div class="patient-stories__modal__content">
            <div class="patient-stories__modal__content">
              <div class="patient-stories__modal__close">
                <button class="patient-stories__modal__close__button">
                  <img src="/themes/custom/envivent/assets/images/global/close-btn.svg" alt="Close" width="24" height="24" loading="lazy">
                </button>
              </div>
              ${fullStory.outerHTML}
            </div>
          </div>
        </div>
      `
      button.addEventListener('click', ()=> {
        const modal = document.createElement('div')
        modal.classList.add('patient-stories__modal')
        modal.style.top = `${window.scrollY}px`
        modal.innerHTML = modalContent
        document.body.appendChild(modal)
        const page = document.querySelector('html')
        page.style.overflow = 'hidden'
        document.body.style.overflow = 'hidden'
  
        const closeButton = modal.querySelector('.patient-stories__modal__close__button')
        closeButton.addEventListener('click', ()=> {
          document.body.removeChild(modal)
          page.style.overflow = 'auto'
          document.body.style.overflow = 'auto'
        })
        
      })
    })
  }
  
  // Patient Resources Page

  const isPatientResourcesPage = window.location.pathname === '/patients-and-advocacy/patient-resources'
  if (isPatientResourcesPage) {
    const resourcesHeader = document.querySelectorAll('.resource-item__header')

    resourcesHeader.forEach(header => {
      header.addEventListener('click', ()=> {
        header.parentElement.parentElement.classList.toggle('active')
      })
    })
  }

  // Search Dynamic on menu

  const menu = document.querySelector('#block-envivent-main-menu .menu')
  const searchWrapper = document.createElement('li')
  searchWrapper.classList.add('search__wrapper')
  const searchBtnTemplate = `
    <button class="search__button">
      <img src="/themes/custom/envivent/assets/images/global/search-icon.svg" alt="Search" class="search__icon">
    </button>
    <div class="search__input_wrapper">
      <button class="search-close__btn">
        <img src="/themes/custom/envivent/assets/images/global/close-btn.svg" alt="Close" width="8" height="8" loading="lazy">
      </button>
      <input type="text" class="search__input">
    </div>
  `

  searchWrapper.innerHTML = searchBtnTemplate
  menu.appendChild(searchWrapper)

  const searchBtn = searchWrapper.querySelector('.search__button')
  const searchInputWrapper = searchWrapper.querySelector('.search__input_wrapper')
  const searchInput = searchWrapper.querySelector('.search__input')
  const searchCloseBtn = searchWrapper.querySelector('.search-close__btn')
  
  searchBtn.addEventListener('click', ()=> {
    if (searchWrapper.classList.contains('active')) {
      handleSearch()
      return
    }
    searchWrapper.classList.add('active')
    searchInput.focus()
  })

  searchInput.addEventListener('input', ()=> {
    if (searchInput.value) {
      searchInput.classList.remove('error')
    }
  })

  searchInput.addEventListener('keypress', (e)=> {
    if (e.key === 'Enter') {
      handleSearch()
    }
  })

  const handleSearch = () => {
    const searchValue = searchInput.value
    if (!searchValue) {
      searchInput.classList.add('error')
      return
    }
    window.location = `/search?keys=${searchValue}`
  }

  searchCloseBtn.addEventListener('click', ()=> {
    searchWrapper.classList.remove('active')
    searchInput.value = ''
    searchInput.classList.remove('error')
  })

  window.addEventListener('resize', ()=> {
    if (window.innerWidth < 1024) {
      searchWrapper.classList.add('active')
    } else {
      searchWrapper.classList.remove('active')
      searchInput.value = ''
    }
  })

  if (window.innerWidth < 1024) {
    searchWrapper.classList.add('active')
  }

  // Hover button

  const hoverBtns = document.querySelectorAll('.hover-button')

  if (hoverBtns.length) {
    hoverBtns.forEach(btn => {
      const link = btn.querySelector('a') || btn.querySelector('button')
      const img = btn.querySelector('img')
      btn.addEventListener('mouseover', ()=> {
        btn.classList.add('hover')
        
        link.addEventListener('mouseover', ()=> {
          btn.classList.add('link-active')
          link.classList.add('hover')
          img.classList.add('hover')
        })
  
        link.addEventListener('mouseleave', ()=> {
          btn.classList.remove('hover')
          btn.classList.remove('link-active')
          link.classList.remove('hover')
          img.classList.remove('hover')
        })
      })
  
      btn.addEventListener('mouseleave', ()=> {
        img.classList.remove('hover')
        link.classList.remove('hover')
        btn.classList.remove('hover')
      })
    })
  }


  // Top menu active

  const currentPath = window.location.pathname
  const topMenuItems = document.querySelectorAll('#block-topmenu li')

  if (topMenuItems.length) {
    topMenuItems.forEach(item => {
      const anchor = item.querySelector('a')
      const parsedHref = anchor.href.replace(window.location.origin, '')
      console.log(parsedHref)
      if (anchor && currentPath.includes(parsedHref)) {
        item.classList.add('active-children')
      }
    })
  } 

  // CTA

  const ctaButtons = document.querySelectorAll('.cta_banner a')
  if (ctaButtons.length) {
    ctaButtons.forEach(cta => {
      const img = cta.querySelector('img')
      const cloneImg = img.cloneNode(true)
      cloneImg.classList.add('hover-image')
      cloneImg.src = "/themes/custom/envivent/assets/images/global/cta-orange-btn.png"
      cta.appendChild(cloneImg)
    })
  }

  // Media menu
  const isMediaPage = window.location.pathname === '/media-center'

  if (isMediaPage) {
    const menuTrigger = document.querySelector('.active_item.mobile-only')
    const cards = document.querySelector('.left-side .card')
    if (menuTrigger) {
      menuTrigger.addEventListener('click', ()=> {
        menuTrigger.classList.toggle('active')
        cards.classList.toggle('active')
      })
    }
  }
  
 
})


