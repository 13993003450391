import 'magnific-popup'
import $ from 'jquery'

// options at: https://dimsemenov.com/plugins/magnific-popup/

class Modal {
  constructor() {
    if (
      document.location.hash !== ''
      && $(document.location.hash).length
      && $(document.location.hash).hasClass('block--type--modal')
    ) {
      $.magnificPopup.open({
        items: {
          src: document.location.hash,
          type: 'inline',
        },
        overflowY: 'scroll'
      })
    }


    $('a').each(function () {
      const href = $(this).attr('href')

      this.addEventListener('click', function (e) {
        if (href) {
          const externalLinkEl = document.querySelector('.external-link')
          if (externalLinkEl) {
            externalLinkEl.innerText = ' ' + href
          }
        }
      })


      if (
        typeof href !== 'undefined'
        && href.startsWith('#')
        && href.length > 1
        && $(href).hasClass('block--type--modal')
      ) {
        console.log(href)

        $(this).magnificPopup({
          items: {
            src: href,
            type: 'inline'
          },
          //type: 'inline',
          overflowY: 'scroll'
        })
      }

      if (
        typeof href !== 'undefined'
        && href.startsWith('http')
        // && !href.includes('ionis')
        && $('#popleave').length
      ) {
   
        $(this).magnificPopup({
          items: {
            src: '#popleave',
            type: 'inline'
          },
          callbacks: {
            open: function() {
              const closeBtn = document.querySelector('.mfp-close')
              const closeBtnImage = document.createElement('img')
              closeBtnImage.src = '/themes/custom/envivent/assets/images/global/close-btn.svg'
              closeBtnImage.alt = 'Close'
              closeBtn.innerHTML = ''
              closeBtn.classList.add('close-btn')
              closeBtn.appendChild(closeBtnImage)

              closeBtn.addEventListener('click', function (e) {
                e.preventDefault
                e.stopPropagation
                $.magnificPopup.close()
                return false
              })

              $('#popleave .ok').on('click', function (e) {
                e.preventDefault
                e.stopPropagation
                window.open(href, '_blank')
                $.magnificPopup.close()
                return false
              })

              $('#popleave .cancel').on('click', function (e) {
                e.preventDefault
                e.stopPropagation
                $.magnificPopup.close()
                return false
              })
            }
          }
        })
      }
    })
  }
}

export default Modal
